<template>
  <div class="container">
    <div class="detail-index-bg">
      <div class="detail-index-bottom clear">
        <div class="detail-index-bottom-bread">
          <span @click="goBack()" style="color:black;cursor:pointer;margin-right:0.1rem">资讯动态</span> /
          <span style="margin-left:0.1rem">{{ info.title }}</span>
        </div>
        <div class="detail-index-bottom-left">
          <div class="detail-index-bottom-left-content clear">
            <div class="detail-index-bottom-left-content-pic">
              <img
                src="https://image.bookgo.com.cn/webculture/jm/homepage/title_icon_left.png"
                alt=""
              />
            </div>

            <div class="detail-index-bottom-left-content-title">
              {{ info.title }}
            </div>
            <div class="detail-index-bottom-left-content-des">
              <ul class="detail-index-bottom-left-content-des-ul clear">
                <li class="detail-index-bottom-left-content-des-ul-li">
                  <span class="detail-index-bottom-left-content-des-ul-li-label"
                    >发布时间：</span
                  >
                  {{ info.publish_time | parseTime("{y}-{m}-{d}") }}
                </li>
                <li class="detail-index-bottom-left-content-des-ul-li">
                  <span class="detail-index-bottom-left-content-des-ul-li-label"
                    >作者：</span
                  >
                  {{ info.publisher_name }}
                </li>
                <li class="detail-index-bottom-left-content-des-ul-li">
                  <span class="detail-index-bottom-left-content-des-ul-li-label"
                    >点击量：</span
                  >
                  {{ info.visit_count }}
                </li>
              </ul>
            </div>
            <div class="detail-index-bottom-left-content-linePic">
              <img
                src="https://image.bookgo.com.cn/webculture/jm/detail/detail-common-crossline.png"
                alt=""
              />
            </div>
          </div>
          <div
            class="detail-index-bottom-left-bottom-summary"
            v-html="info.content"
          >
            {{ info.content }}
          </div>
        </div>
      </div>
      <div class="downFile-all" v-if="attachsArray.length > 0">
        <div class="file-title">
          <img style="vertical:middle" src="https://image.bookgo.com.cn/%20webculture/jm/fujianjinmen.png" />
          <span style="margin-left:0.05rem;vertical-align:middle">附件</span>
        </div>
        <div class="file-list" v-for="(item,i) in attachsArray" v-bind:key="i">          
          <span>{{item.name}}</span>
          <a :href=item.url style="display: inline-block;"> 
            <span class="downButton">下载</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { info as articleinfo, list } from "@/api/article";
export default {
  data() {
    return {
      id: null,
      info: {},
      detailType: "news",
      news_team_list: [
        {
          cover: "",
          title: " 武穴市歌声悠扬艺术团",
          summary: "2020-00-00至2020-00-00",
        },
        {
          cover: "",
          title: " 武穴市公共文化志愿者团队",
          summary:
            "阿胶是东方科技大厦能否看见的是苦涩的烦恼都是看法你打开手机菲尼克斯的烦恼上岛咖啡的时刻开始的菲尼克斯觉得你烦开始的你发开始大家菲尼克斯地方是看到你发就开始的你发开始的你发",
        },
        {
          cover: "",
          title: " 武穴市公共文化志愿者团队",
          summary: "60",
        },
      ],
      attachsArray:[]
    };
  },
  created() {
    let id = this.$route.query.id;
    if (!id) {
      this.$router.push({ path: "/" });
    }
    this.id = id;
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    // window.scroll(0, 0);
    this.getInfo();
  },
  watch: {
    $route: function(newVal) {
      // console.log(newVal,oldVal);
      this.id = newVal.query.id;
      this.getInfo();
    },
  },
  methods: {
    handleClosed() {},
    getInfo() {
      articleinfo({ id: this.id }).then((res) => {
        console.log(res)
        console.log(res.data.article.attachs)
        var attachs = JSON.parse(res.data.article.attachs)
        this.attachsArray = attachs
        console.log(this.attachsArray)
        const regex = new RegExp("<img", "gi");
        res.data.article.content = res.data.article.content.replace(
          regex,
          `<img style="max-width: 100%; height: auto;margin:0 auto"`
        );
        this.info = res.data.article;
        console.info(this.info);
      });
    },
    goBack() {
      console.log(this.$route)
      var cat_id = this.$route.query.cat_id
      var navId = this.$route.query.navId
      var id = this.$route.query.pid
      var name = this.$route.query.name
      console.log(cat_id)
      console.log(navId)
      console.log(id)
      this.$router.push({path:'/news',query:{name:name,id:id,cat_id:id,navId:navId}});
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/detail.scss";
</style>
<style lang="scss" scoped>

</style>
